.container {
  max-width: 295px;

  .heading {
    background: none;
    border-bottom: none;
    box-shadow: none;
  }

  .content {
    display: flex;
    padding: 0px 32px 32px 32px;
    flex-direction: column;
  }

  .buttonsWrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
  }
}
