.caption {
  display: none;
  white-space: nowrap;
  @media (min-width: 700px) {
    display: inline;
  }
}

.shortCaption {
  display: inline;
  @media (min-width: 700px) {
    display: none;
  }
}
