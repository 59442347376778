.favoritesButton {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.favoritesIcon {
  fill: var(--color-white);
  color: var(--color-grey-1);

  &.active {
    & :global(.path) {
      fill: var(--color-orange);
      color: var(--color-orange);
    }

    &:hover {
      fill: var(--color-white);
      & :global(.path) {
        fill: var(--color-white);
      }
    }
  }

  &:hover {
    fill: var(--color-grey-4);
  }
}
