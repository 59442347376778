.chip {
  fill: currentColor;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  gap: var(--padding-inner-xxs);
  padding: var(--padding-inner-xxs) var(--padding-inner-xs);
  border-radius: 40px;
  color: var(--color-primary);
  background-color: var(--color-grey-6);
  cursor: default;
  line-height: 19px;
  white-space: nowrap;
  flex: 0 0 auto;

  .remove {
    border: none;
    background: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex: 0 0 auto;
  }
}

.chipWrap {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-color: var(--color-grey-6);
  padding: var(--padding-inner-xxs);
  border-radius: 16px;
  white-space: nowrap;
  flex: 0 0 auto;
  //flex-wrap: wrap;

  .chip {
    background-color: var(--color-grey-4);
  }

  .chipWrapRemove {
    border: none;
    background: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-right: var(--padding-inner-xs);
    color: var(--color-primary);
  }
}

.chipParent {
  color: var(--color-primary);
  margin-left: var(--padding-inner-xs);
  cursor: default;
}
