.list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-inner-xxs);

  .listItem {
    display: inline-block;
    background-color: var(--color-grey-6);
    padding: var(--padding-inner-xxs) var(--padding-inner-xs);
    border-radius: var(--border-radius-sm);
  }

  .highlighted {
    background-color: var(--color-grey-5);
  }
}