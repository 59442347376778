.dialog {
  display: flex;
  flex-direction: column;
  position: fixed;
  background: var(--color-white);
  top: 0;
  bottom: 0;
  height: 100%;
  right: 0;
  z-index: 1200;
  max-width: 80%;
  min-width: 300px;
  width: 700px;
  border-radius: 0;

  @media (max-width: 500px) {
    min-width: unset;
    max-width: unset;
    width: 100%;
  }
}

.dialogBody {
  padding: var(--padding-inner-md);
  flex: 1 1 auto;
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: 1fr 1fr;
  place-content: flex-start;
  gap: var(--padding-inner-xs);
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
}

.notice {
  grid-column: 1 / -1;
  border: 1px solid var(--color-grey-5);
  background-color: var(--color-grey-7);
  padding: var(--padding-inner-sm);
  border-radius: var(--border-radius-md);
}
