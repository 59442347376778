.search {
  width: 100%;

  input[type='search'] {
    -webkit-appearance: textfield;
    appearance: textfield;
    outline: none;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }
}

.popover {
  background: #fff;
  overflow-y: auto;
  border: 1px solid var(--color-grey-6);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow);
  z-index: 30;
}

.resetButton {
  color: var(--color-grey-3);
  flex: 0 0 auto;
  border: none;
  background: none;
  height: 32px;
  width: 32px;
  margin-right: 2px;

  &:hover {
    color: var(--color-primary);
    cursor: pointer;
  }
}
