.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
  margin: var(--padding-inner-lg) auto;

  & a:only-child {
    align-self: flex-end;
    margin-left: auto;
  }
}
