.srpChips,
.chips {
  display: flex;
  align-items: center;
  gap: var(--padding-inner-xs);
  justify-content: flex-start;
  overflow: hidden;
  position: relative;

  &.showAllChips {
    flex-wrap: wrap;
  }
}

.srpChips {
  margin-top: var(--padding-inner-sm);
  display: none;
  @media (min-width: 500px) {
    display: flex;
  }
}

.showAllChipsButton {
  position: absolute;
  right: 0;
  border: 0;
  height: 100%;
  background: var(--color-white);
  color: var(--color-grey-6);
  font-size: var(--font-size-caption);
  text-decoration: underline;
  cursor: pointer;
  padding: 0 var(--padding-inner-sm);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -60px;
    width: 60px;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 95%
    );
  }
}
