.gallery {
  margin: var(--padding-inner-xxl) 0;
  h2 {
    padding: 0 var(--padding-inner-sm);
    margin-bottom: var(--padding-inner-xs);
  }
}

.listings {
  padding: 0 var(--padding-inner-xs);
}
