.seoContent {
  font-size: var(--font-size-caption);
  margin: 0 var(--padding-inner-md) var(--padding-inner-md);
  display: flex;
  flex-direction: column;
  gap: var(--padding-inner-md);

  h4 {
    font-weight: 500;
    margin-bottom: var(--padding-inner-xs);
  }

  .seoText {
    white-space: balance;

    br {
      display: none;
    }
  }

  .related {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: var(--padding-inner-xs) var(--padding-inner-sm);
    overflow: hidden;

    li {
      white-space: nowrap;
    }
  }

  .header {
    font-weight: 500;
  }

  .showMore {
    margin: var(--padding-inner-sm) 0;
  }

  a {
    color: var(--color-secondary);
    text-decoration: underline;
  }
}
