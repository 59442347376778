.iconButton {
  background: var(--color-white);
  border: 1px solid var(--color-grey-5);
  padding: var(--padding-inner-xs);
  border-radius: 50%;
  color: var(--color-grey-3);

  &.disabled {
    border-color: var(--color-grey-6);
    color: var(--color-grey-5);
  }
}
