.gallerySlider {
  position: relative;
  overflow: hidden;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--padding-inner-xs);
    margin-bottom: var(--padding-inner-xs);
  }

  .info {
    display: flex;
    align-items: center;
    gap: var(--padding-inner-xs);
    flex-wrap: wrap-reverse;

    @media (min-width: 500px) {
      flex-wrap: wrap;
    }
  }

  .infoLabel {
    display: inline-block;
    background-color: var(--color-grey-6);
    padding: var(--padding-inner-xxs) var(--padding-inner-xs);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-meta);
    line-height: normal;
  }

  .infoText {
    line-height: normal;
  }

  .slide-container {
    padding-top: var(--base-padding); /* make sure top shadows are visible */
    padding-bottom: var(
      --base-padding
    ); /* make sure bottom shadows are visible */
    scroll-snap-type: x mandatory;
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: flex-start;

    grid: auto / auto-flow minmax(var(--slide-min-width, 232px), 236px); /* 232px = min width of slide 216px + 8px left-shadow + 8px right-shadow; same logic for max width 236px */
    gap: 0px; /* 0 because of the 8px for left shadow & 8px for right shadow that also create the "gap" */

    overscroll-behavior-inline: contain;
    touch-action: pan-x pan-y pinch-zoom;

    contain: layout paint style;
    overflow: visible hidden;

    // TODO: Consider if this is the way to go? The windows scroll looks horrible, but we could style it?
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    &::-webkit-scrollbar {
      background: transparent; /* Chrome/Safari/Webkit */
      width: 0px;
      height: 0px;
    }
  }

  .slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    position: relative;
  }

  .next,
  .prev {
    pointer-events: all;
    transition: all 250ms ease-in-out;

    &:not(:disabled) {
      cursor: pointer;
    }

    @media (hover: none), (pointer: coarse) {
      display: none;
    }
  }

  .next {
    margin-left: 8px;
  }

  .prev {
    margin-right: 8px;
  }
}
