.header {
  padding: 0 var(--padding-inner-sm);
  display: none;
  grid-template-columns: var(--list-view-columns);
  gap: var(--padding-inner-sm);

  @media (min-width: 890px) {
    display: grid;
  }

  .sortableHeader {
    border: 0;
    display: flex;
    align-items: center;
    background: none;
    cursor: pointer;
    font-size: var(--font-size-caption);

    .carret {
      flex: 0 0 auto;
    }

    & > button {
      background: none;
      cursor: pointer;
      border: 0;
    }

    &.active,
    & .active {
      color: var(--color-secondary);
      font-weight: 500;
    }

    &:last-child {
      justify-self: flex-end;
    }

    .customColumn {
      background: none;
      color: var(--color-secondary);
      padding-right: 6px;
      margin-right: 2px;
      border-right: 1px solid var(--color-grey-4);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.popover {
  width: max-content;
  max-width: calc(100vw - 20px);
  max-height: 70vh;
  overflow-y: auto;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-6);
  border-radius: var(--border-radius-sm);
  z-index: 30;
  outline: 0;
  box-shadow: var(--shadow);
}

.item {
  border: none;
  border-bottom: 1px solid var(--color-grey-6);
  background: none;
  display: flex;
  width: 100%;
  min-width: 110px;
  margin: 0;
  outline: 0;
  padding: var(--padding-inner-xs) var(--padding-inner-sm);
  border-radius: var(--border-radius-sm);
  cursor: pointer;

  &:last-of-type {
    border: none;
  }

  &.active {
    color: var(--color-primary);
  }

  &:focus,
  &:not([disabled]):active {
    background: var(--color-grey-7);
  }
}
