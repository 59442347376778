.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0 5px;
  font-size: var(--font-size-meta);
  font-weight: 700;
  line-height: 20;
  background-color: var(--color-primary);
  color: var(--color-white);
  height: 20px;
  min-width: 20px;
  // animation-name: scale;
  // animation-duration: 500ms;
}

// @keyframes scale {
//   0% {
//     transform: scale(1);
//   }
//   25% {
//     transform: scale(1);
//   }
//   50% {
//     transform: scale(2);
//   }
//   100% {
//     transform: scale(1);
//   }
// }
