.listing {
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-6);
  box-shadow: var(--shadow);
  border-radius: var(--border-radius-md);
  position: relative;
  overflow: hidden; // Fixes old safari bug, but limits what can be done within the listing e.g. tooltips/popovers
  width: calc(100% - 16px); // 16px = 8px shadow left + 8px shadow right
  height: 100%;

  .listingWrap {
    height: 100%;
    display: grid;
    gap: 8px;

    grid-template-rows: minmax(auto, min-content) 1fr auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      'media'
      'title'
      'price';
  }

  .link {
    position: absolute;
    inset: 0;
  }

  .media {
    grid-area: media;
    margin: var(--padding-inner-xs) var(--padding-inner-xs) 0
      var(--padding-inner-xs);
    position: relative;

    .image {
      aspect-ratio: 4/3;
      position: relative;
      border-radius: var(--border-radius-sm);
      overflow: clip;
    }

    .imageLink {
      position: absolute;
      inset: 0;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 20%;
        background: linear-gradient(
          180deg,
          rgba(75, 75, 75, 0) 7.5%,
          rgba(75, 75, 75, 0.38) 24.36%,
          rgba(53, 53, 53, 0.76) 51.34%,
          rgba(0, 0, 0, 0.95) 100%
        );
      }
    }

    .mediaIcons {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: row;
      gap: var(--padding-inner-xs);
      padding: var(--padding-inner-xs);
      color: var(--color-white);
    }
  }

  .makeModel {
    grid-area: title;
    padding: 0 var(--padding-inner-sm);
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    & > div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      & > h3 {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .price {
    grid-area: price;
    padding: 0 var(--padding-inner-sm);
    margin-bottom: var(--padding-inner-xs);
  }
}
