.sorting {
  display: flex;
  align-items: center;
  gap: var(--padding-inner-xs);

  & button span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }

  & svg {
    flex: 0 0 auto;
  }
}

.popover {
  width: max-content;
  max-width: calc(100vw - 20px);
  max-height: 70vh;
  overflow-y: auto;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-6);
  border-radius: var(--border-radius-sm);
  z-index: 30;
  outline: 0;
  box-shadow: var(--shadow);
}

.item {
  border: none;
  border-bottom: 1px solid var(--color-grey-6);
  background: none;
  display: flex;
  width: 100%;
  min-width: 110px;
  margin: 0;
  outline: 0;
  padding: var(--padding-inner-xs) var(--padding-inner-sm);
  border-radius: var(--border-radius-sm);
  cursor: pointer;

  &:last-of-type {
    border: none;
  }

  &.active {
    color: var(--color-primary);
  }

  &:focus,
  &:not([disabled]):active {
    background: var(--color-grey-7);
  }
}
