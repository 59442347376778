.top {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 var(--padding-inner-sm);
  margin-bottom: var(--padding-inner-md);
  flex-wrap: wrap;
  gap: var(--padding-inner-xs);

  @media (min-width: 800px) {
    padding: 0 var(--padding-inner-md);
  }

  @media (max-width: 620px) {
    flex-direction: column;
  }

  .sortingDisplayControls {
    display: flex;
    gap: 24px;
    align-items: center;

    @media (max-width: 620px) {
      justify-content: space-between;
    }
  }
}

.filtersButton {
  height: 48px;
}

.filters {
  display: flex;
  gap: calc(var(--padding) * 2);
  margin-bottom: calc(var(--padding) * 3);
  padding-bottom: calc(var(--padding) * 3);
  border-bottom: 1px solid #ddd;
}

.filter {
  border-bottom: 1px solid var(--color-disabled, #dfdfdf);
  padding-bottom: var(--padding, 8px);
  margin-bottom: var(--padding, 8px);

  .name {
    display: flex;
    justify-content: space-between;
  }

  .value {
    color: var(--color-primary);
    font-size: 14px;
  }
}

.results {
  --preview-aspect-ratio: 3/2;
  --preview-aspect-ratio-fallback-padding: 50%;
  --preview-aspect-ratio-fallback-position: relative;

  --color-card-background: transparent;

  --list-view-columns: 200px 2fr 80px 130px minmax(0, 1.5fr) minmax(0, 1.5fr);

  display: grid;
  grid-auto-flow: row dense;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(var(--grid-columns, 1), 1fr);
  gap: var(--grid-gap, var(--padding-inner-sm));
  padding-bottom: calc(var(--padding) * 3);
  padding: 0 var(--padding-inner-xs);

  @media (min-width: 500px) {
    grid-template-columns: repeat(var(--grid-columns, 2), 1fr);
  }

  @media (min-width: 800px) {
    grid-template-columns: repeat(var(--grid-columns, 3), 1fr);
    padding: 0 var(--padding-inner-sm);
  }

  &:global(.listView) {
    grid-template-columns: 1fr;

    @media (max-width: 700px) {
      gap: var(--padding-inner-xs);
    }
  }
}

.intermingleAdRow {
  margin: 0 calc(var(--padding-inner-sm) * -1);
  place-self: center;
  max-width: 100vw;
  overflow-x: hidden;
}

.stickyActions {
  position: sticky;
  top: 0;
  // Adnami have z-index 10 and is added into the DOM late
  // set z-index to 11 to make sure sticky actions are on top
  z-index: 11;
  background: var(--color-background-header);
  padding: var(--padding-inner-sm);
  margin-bottom: var(--padding-inner-md);
  border-bottom: 1px solid var(--color-grey-6);

  @media (min-width: 800px) {
    padding: var(--padding-inner-md) var(--padding-inner-md)
      var(--padding-inner-sm);
  }
}

.freeTextSearchContainer {
  width: 368px;
  min-width: 0;
}

.searchAgent {
  display: flex;
  gap: 8px;

  button {
    @media (max-width: 550px) {
      min-width: 50px;
    }
  }
}

.carOfTheYear {
  display: flex;
  gap: var(--padding-inner-sm);
  justify-content: space-between;
  margin: 0 var(--padding-inner-xs) var(--padding-inner-md);
  text-align: center; // For alt-text
  word-break: break-word; // For alt-text

  @media (min-width: 800px) {
    margin: 0 var(--padding-inner-sm) var(--padding-inner-md);
  }

  a {
    width: 100%;
  }

  @media (max-width: 500px) {
    a ~ a {
      display: none;
    }
  }

  img {
    width: 100%;
    background-color: var(--color-grey-6);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow);
  }
}
