.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--padding-inner-xs);

  button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: var(--border-radius-sm);
  }
}

.option {
  cursor: pointer;
  color: var(--color-grey-3);

  &:hover {
    color: var(--color-primary);
  }

  &.active {
    color: var(--color-primary);
  }
}
