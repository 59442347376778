.pagination {
  // Positioning - consider removing to allow more flexibility
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  // Specific width
  max-width: calc(
    calc(2 * var(--indicator-margin, 3px) + var(--indicator-size, 6px)) *
      var(--indicator-max-dots, 5) + 8px
  ); // 5 x 6px x (2 x 3px) + 8px (the 8px corresponds to the padding below)
  overflow: hidden;
  overflow: clip;

  // background: rgba(0, 0, 0, 0.2);
  // border-top-left-radius: var(--indicator-border-radius, 3px);
  // border-top-right-radius: var(--indicator-border-radius, 3px);
  padding: 2px 4px;

  .dot-container {
    width: max-content;
    display: flex;
    align-items: center;
    transition: transform 500ms ease-in-out;
  }

  .dot {
    background-color: #fff;
    display: block;
    height: var(--indicator-size, 6px);
    width: var(--indicator-size, 6px);
    border-radius: var(--indicator-size, 6px);
    margin: var(--indicator-margin, 3px);
    opacity: 0.5;
    transform: scale(0.5);
    transition: transform 200ms ease-in-out;

    // Sizing carousel dots using CSS - would prefer that the initial dots were full size when close to the edge, but this is close enough
    &:has(+ .active) {
      transform: scale(0.8);
    }

    &.active {
      opacity: 1;
      transform: scale(1);

      & + .dot {
        transform: scale(0.8);
      }
    }
  }
}
