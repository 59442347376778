.listing {
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-6);
  box-shadow: var(--shadow);
  border-radius: var(--border-radius-md);
  position: relative;
  overflow: hidden; // Fixes old safari bug, but limits what can be done within the listing e.g. tooltips/popovers

  .properties {
    display: none;
  }

  :global(.listView) & {
    .dealerLogoCard,
    .top {
      display: none;
    }

    .dealerLogoList {
      display: block;
    }

    .listingWrap {
      padding: var(--padding-inner-sm) var(--padding-inner-sm)
        var(--padding-inner-xs);
      grid-template-columns: 200px 1fr min-content;
      grid-template-rows: min-content 1fr min-content;
      grid-gap: var(--padding-inner-xs) var(--padding-inner-sm);
      grid-template-areas:
        'media title price'
        'media details details'
        'media location location';
    }

    @media (min-width: 890px) {
      .listingWrap {
        grid-template-columns: var(--list-view-columns);
        grid-template-rows: 1fr min-content;
        grid-template-areas:
          'media title details details details price'
          'media location location location location location';

        .details {
          display: none;
        }

        .properties {
          display: grid;
        }

        .makeModel {
          flex-direction: column;
          justify-content: flex-start;

          & .carOfTheYear {
            display: block;
          }
        }
      }
    }

    @media (max-width: 630px) {
      .listingWrap {
        padding: var(--padding-inner-xs);
        grid-template-columns: 180px 1fr min-content;

        .media {
          margin: 0;
        }

        .details {
          font-size: var(--font-size-meta);
        }

        .dealerLogoList {
          max-height: 22px;
        }

        .mediaIcons svg {
          width: 14px;
          height: 14px;
        }

        .favoriteButton {
          svg {
            height: 28px;
            width: 28px;
          }
        }
      }
    }

    @media (max-width: 500px) {
      .listingWrap {
        grid-template-columns: minmax(140px, 1.5fr) 2fr 1fr;

        .details {
          font-size: var(--font-size-meta);
        }
      }
    }

    @media (max-width: 400px) {
      .listingWrap {
        grid-template-columns: 140px 1fr;
        grid-template-areas:
          'media title'
          'media price'
          'details details'
          'location location';
      }

      .dots {
        --indicator-size: 4px;
      }

      .makeModel {
        font-size: var(--font-size-caption);
      }

      .mediaIcons {
        top: 0;
        bottom: unset;
        gap: 4px;
      }
    }

    .details {
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 12px;

      & .carOfTheYear {
        display: block;
      }
    }

    .location {
      padding: var(--padding-inner-xs) 0 0;
      min-height: unset;
    }

    .makeModel {
      padding: 0;

      & .carOfTheYear {
        display: none;
      }
    }

    .media {
      margin: calc(-1 * var(--padding-inner-xs));
      margin-right: 0;
      margin-bottom: 0;
    }

    .top {
      padding: 0;
      justify-self: flex-end;
    }

    .price {
      padding: 0;
      justify-self: flex-end;
    }
  }

  .dealerLogoCard {
    max-height: 32px;
  }

  .dealerLogoList {
    display: none;
    max-height: 25px;
  }

  .carOfTheYear {
    width: 70px;
    height: 70px;
    margin: -10px;
    pointer-events: none;
  }

  .listingWrap {
    height: 100%;
    display: grid;
    gap: 8px;

    grid-template-rows:
      calc(32px + var(--padding-inner-xs)) auto minmax(64px, min-content)
      auto 1fr auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      'top'
      'media'
      'title'
      'price'
      'details'
      'location';
  }

  .link {
    position: absolute;
    inset: 0;
  }

  .top {
    grid-area: top;
    padding: var(--padding-inner-xs) var(--padding-inner-xs) 0;
    //min-height: 32px;
    display: flex;
    align-items: center;
    gap: 8px;

    &:empty {
      display: none;
    }

    .banners {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      gap: var(--padding-inner-xs);
    }

    .actions {
      flex: 0 0 auto;
      min-width: 0;
      pointer-events: all;
      z-index: 2;

      button {
        display: block;
      }
    }
  }

  .media {
    grid-area: media;
    margin: 0 var(--padding-inner-xs);
    position: relative;

    .image {
      aspect-ratio: 4/3;
      position: relative;
      border-radius: var(--border-radius-sm);
      overflow: clip;
    }

    .imageLink {
      position: absolute;
      inset: 0;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 20%;
        background: linear-gradient(
          180deg,
          rgba(75, 75, 75, 0) 7.5%,
          rgba(75, 75, 75, 0.38) 24.36%,
          rgba(53, 53, 53, 0.76) 51.34%,
          rgba(0, 0, 0, 0.95) 100%
        );
      }
    }

    .mediaIcons {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: row;
      gap: var(--padding-inner-xs);
      padding: var(--padding-inner-xs);
      color: var(--color-white);
    }

    .favoriteButton {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: var(--padding-inner-xs);
      pointer-events: all;
    }

    .newListingBadge {
      position: absolute;
      top: 0;
      right: 0;
      color: var(--color-white);
      background: var(--color-green);
      font-weight: bold;
      font-size: var(--font-size-meta);
      padding: 4px 8px;
      border-bottom-left-radius: var(--border-radius-sm);
    }
  }

  .makeModel {
    grid-area: title;
    padding: 0 var(--padding-inner-sm);
    display: flex;
    justify-content: space-between;
  }

  .price {
    grid-area: price;
    padding: 0 var(--padding-inner-sm);
  }

  .details {
    grid-area: details;
    padding: 0 var(--padding-inner-sm);
    font-size: var(--font-size-caption);

    & .carOfTheYear {
      display: none;
    }
  }

  .properties {
    grid-area: details;
    display: none;
    font-size: var(--font-size-caption);
    grid-template-columns: 80px 130px minmax(0, 1fr);
    grid-template-rows: min-content;
    grid-gap: var(--padding-inner-sm);
    justify-items: flex-start;

    & > div {
      background-color: var(--color-grey-6);
      padding: var(--padding-inner-xxs) var(--padding-inner-xs);
      border-radius: var(--border-radius-sm);
    }
  }

  .location {
    grid-area: location;
    color: var(--color-grey-2);
    font-size: var(--font-size-caption);
    padding: var(--padding-inner-xs) 0 var(--padding-inner-xxs)
      var(--padding-inner-sm);
    border-top: 1px solid var(--color-grey-6);
    min-height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--padding-inner-xxs);

    div {
      display: flex;
      gap: var(--padding-inner-xxs);
      align-items: center;
    }
  }

  .description {
    grid-area: description;
    padding: 0 var(--padding-inner-sm);
    font-size: var(--font-size-caption);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: none;
  }
}
