.carousel {
  position: relative;

  .overlay {
    position: absolute;
    inset: 0;
    pointer-events: none;
    z-index: 2;
    // box-shadow: inset 0px -12px 8px -4px rgb(0 0 0 / 5%);
  }

  .favorite {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &.inview .slide-container {
    contain: layout paint size style;
    overflow: visible hidden;
  }

  .slide-container {
    scroll-snap-type: x mandatory;
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    grid-template-rows: none;
    grid-template-areas: none;
    justify-content: flex-start;

    overscroll-behavior-inline: contain;
    touch-action: pan-x pan-y pinch-zoom;

    contain: none;
    overflow: hidden;

    // TODO: Consider if this is the way to go? The windows scroll looks horrible, but we could style it?
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    &::-webkit-scrollbar {
      background: transparent; /* Chrome/Safari/Webkit */
      width: 0px;
      height: 0px;
    }
  }

  .slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    position: relative;
  }

  .next,
  .prev {
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 20;
    pointer-events: all;
    transition: all 250ms ease-in-out;
    cursor: pointer;

    &:hover {
      transform: translateY(-50%) scale(1.1);
    }

    @media (hover: none) and (pointer: coarse) {
      display: none;
    }
  }

  &:hover {
    .next,
    .prev {
      opacity: 1;
    }
  }

  .next {
    right: 8px;
  }

  .prev {
    left: 8px;
  }
}
