.overlay {
  background: unset;
  place-items: start center;
  padding-top: 40px;
}

.container {
  max-width: 400px;

  .content {
    display: flex;
    padding: 0 0 0 0;
    flex-direction: column;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .alert {
    margin: unset;
  }
}
