.searchAgentDialog {
  display: flex;
  flex-direction: column;
  position: fixed;
  background: var(--color-white);
  top: 0;
  bottom: 0;
  height: 100%;
  right: 0;
  z-index: 1200;
  max-width: 75%;
  min-width: 300px;
  width: 650px;
  border-radius: 0;

  @media (max-width: 500px) {
    min-width: unset;
    max-width: unset;
    width: 100%;
  }
}

.dialogBody {
  padding: var(--padding-inner-md);
  flex: 1 1 auto;
  flex-direction: column;
  gap: var(--padding-inner-xs);
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: 500px) {
    padding: var(--padding-inner-sm);
  }
}

.searchAgentBtn {
  gap: 0;

  .searchAgentBtnText {
    font-size: var(--font-size-body);
    padding-left: var(--base-padding);
    white-space: nowrap;
  }
}

.searchAgentBtnText {
  @media (max-width: 550px) {
    display: none;
  }
}

.searchAgentInfo {
  padding: var(--padding-inner-xs) var(--padding-inner-sm);
  background-color: var(--color-grey-7);
  border-radius: var(--border-radius-sm);
  margin-bottom: var(--padding-inner-md);
  display: flex;
  align-items: center;
  gap: var(--padding-inner-sm);

  @media (max-width: 550px) {
    margin-bottom: var(--padding-inner-sm);
  }

  .searchIllustrationWrapper {
    flex-shrink: 0;
    @media (max-width: 550px) {
      width: 80px;
      height: auto;
    }
  }

  p {
    color: var(--color-primary);
    margin-bottom: 0;
  }
}

.searchNameWrapper {
  background-color: var(--color-grey-7);
  padding: var(--padding-inner-sm);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--color-grey-6);
  margin-bottom: var(--padding-inner-md);

  @media (max-width: 550px) {
    margin-bottom: var(--padding-inner-sm);
  }

  .title {
    color: var(--color-primary);
    font-weight: bold;
    padding-bottom: var(--padding-inner-sm);

    @media (max-width: 550px) {
      font-size: 16px;
    }
  }

  .search {
    margin-bottom: var(--padding-inner-md);
    border: 1px solid #7c848c;
    color: var(--color-grey-2);

    @media (max-width: 550px) {
      margin-bottom: 0;
    }
  }
}

.contactPreferences {
  background-color: var(--color-grey-7);
  padding: var(--padding-inner-sm);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--color-grey-6);

  .title {
    color: var(--color-primary);
    font-weight: bold;
    padding-bottom: var(--padding-inner-sm);
    margin: 0;

    @media (max-width: 550px) {
      font-size: 16px;
    }
  }

  .contactCheckboxes {
    display: flex;
    gap: var(--padding-inner-md);
    padding-bottom: var(--padding-inner-sm);
  }

  .noCostOptOut {
    color: var(--color-grey-2);
    font-size: var(--font-size-meta);
    margin-bottom: 0;
  }
}

.searchagentCreatedContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .title {
    color: var(--color-primary);
    padding-top: var(--padding-inner-sm);
  }

  .subtitle {
    padding-bottom: var(--padding-inner-md);
  }

  .text {
    padding: 0 0 var(--padding-inner-md) 0;
    color: var(--color-grey-2);
    font-size: var(--font-size-caption);
  }
}
