.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--padding-inner-sm) var(--padding-inner-xs);
  position: sticky;
  top: 0;
  z-index: 10;
}

.dialog {
  display: flex;
  flex-direction: column;
  position: fixed;
  background: var(--color-white);
  top: 0;
  bottom: 0;
  height: 100%;
  right: 0;
  z-index: 1200;
  max-width: 80%;
  min-width: 300px;
  width: 700px;
}

.dialogBody {
  padding: var(--padding-inner-md);
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: var(--padding-inner-xs);
  overflow-y: scroll;
  overflow-x: hidden;
}

.filterTypeHeader {
  color: var(--color-grey-2);
  margin-top: var(--padding-inner-lg);
  margin-bottom: var(--padding-inner-xs);

  &:first-of-type {
    margin-top: 0;
  }
}
